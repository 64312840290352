exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-before-and-after-index-tsx": () => import("./../../../src/pages/before-and-after/index.tsx" /* webpackChunkName: "component---src-pages-before-and-after-index-tsx" */),
  "component---src-pages-contact-us-index-tsx": () => import("./../../../src/pages/contact-us/index.tsx" /* webpackChunkName: "component---src-pages-contact-us-index-tsx" */),
  "component---src-pages-deep-fractional-rf-index-tsx": () => import("./../../../src/pages/deep-fractional-rf/index.tsx" /* webpackChunkName: "component---src-pages-deep-fractional-rf-index-tsx" */),
  "component---src-pages-highly-customized-treatment-index-tsx": () => import("./../../../src/pages/highly-customized-treatment/index.tsx" /* webpackChunkName: "component---src-pages-highly-customized-treatment-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-superior-technology-index-tsx": () => import("./../../../src/pages/superior-technology/index.tsx" /* webpackChunkName: "component---src-pages-superior-technology-index-tsx" */)
}

