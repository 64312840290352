require('./src/styles/index.scss');

exports.shouldUpdateScroll = ({
  routerProps: { location },
}) => {
  const { pathname } = location;
  // list of routes for the scroll-to-top-hook
  const scrollToTopRoutes = ['/superior-technology/', '/highly-customized-treatment/', '/deep-fractional-rf/', '/contact-us/', '/global-distributors/', '/before-and-after/', '/about/'];
  // if the new route is part of the list above, scroll to top (0, 0)
  if (scrollToTopRoutes.indexOf(pathname) !== -1) {
    document.body.scrollTo(0, 0);
  }

  return false;
};
